.strip_container {
  background-color: #ffeecc;
  padding: 10px;
  min-height: 34px;
  border-radius: 8px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.strip_main {
  display: flex;
  justify-content: space-between;
}

.spinner_style {
  width: 18px !important;
  height: 18px !important;
}

.spinner_text_container {
  display: flex;
  gap: 12px;
  align-items: center;
}

.sync_time_text {
  font-style: italic !important;
  margin-left: 30px;
  margin-top: 6px;
  letter-spacing: normal !important;
}

.main_text {
  color: #640;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  width: fit-content;
  line-height: 16px;
  letter-spacing: 0.24px;
}

.sub_text {
  color: #640;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.24px;
}

.icon_button_style {
  padding: 3px !important;
}

.icon_style {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .strip_container {
    width: calc(100% + 32px);
    margin-top: -16px;
    margin-left: -16px;
    border-radius: 0%;
  }
}
